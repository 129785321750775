/* Kondapaneni, Charan Sai - 1002065248 || 
Ketkar, Abhishek - 1002065640 
Kunchakuri, Harshitha - 1002111389
Rajendran, Naveen Kumar - 1002039449
Rachakonda, Sai Prasanna - 1002090739
*/
.class-search{
    text-align:center;
    font-size: larger;
}
h2{
    text-align: center;
    font-size: xx-large;
}
p{
    font-size: larger;
}
.p1{
    margin-top: 0.1px;
    margin-left: 40px;
    position: absolute;
}
.coursecode{
    height: 30px;
    width: 200px;
    font-size: large;
}
.coursecode1{
    height: 30px;
    width: 100px;
    font-size: large;
}
table {
    border-collapse: collapse;
    width: 90%;
    margin: 0 auto;
}

th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #333;
    color: #fff;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #ddd;
}
.tablecss{
    margin-top: 10%;
}
a{
    text-decoration: none;
    color: #000;
}