/* Kondapaneni, Charan Sai - 1002065248 || 
Ketkar, Abhishek - 1002065640 
Kunchakuri, Harshitha - 1002111389
Rajendran, Naveen Kumar - 1002039449
Rachakonda, Sai Prasanna - 1002090739
*/
.footer {
    position:absolute;
    bottom:0;
    width: 100vw;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #302f49;
}

.footer .copy {
    margin-left: 30px;
    color: #fff;
}

.bottom-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
}

.bottom-links .links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.bottom-links .links span {
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    margin: 10px 0;
}

.bottom-links .links a {
    text-decoration: none;
    color: #a1a1a1;
    padding: 10px 20px;
}