/* Kondapaneni, Charan Sai - 1002065248 || 
Ketkar, Abhishek - 1002065640 
Kunchakuri, Harshitha - 1002111389
Rajendran, Naveen Kumar - 1002039449
Rachakonda, Sai Prasanna - 1002090739
*/
table {
    border-collapse: collapse;
    border: solid 1px black;
    border-width: 10%;
    border-color: #000;
    width: 100%;
    margin: 0 auto;
}
th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
}