/* Kondapaneni, Charan Sai - 1002065248 || 
Ketkar, Abhishek - 1002065640 
Kunchakuri, Harshitha - 1002111389
Rajendran, Naveen Kumar - 1002039449
Rachakonda, Sai Prasanna - 1002090739
*/
*{
    margin : 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
body{
    background-color: white;
}
.chatbot-toggler{
    position: absolute;
    right: 40px;
    bottom: 50px;
    height: 50px;
    width: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #724ae8;
    border-radius: 50%;
    transition: all 0.6s ease;
}
.show-chatbot .chatbot-toggler{
    transform: rotate(90deg);
}
.chatbot-toggler span{
    position: absolute;
}
.show-chatbot .chatbot-toggler span:first-child,
.chatbot-toggler span:last-child{
    opacity: 0;
}
.show-chatbot .chatbot-toggler span:last-child{
    opacity: 1;
}
.chatbot{
    position: absolute;
    right: 40px;
    bottom: 120px;
    width: 420px;
    transform: scale(0.5);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    background-color: #dcd3d3;
    border-radius: 15px;
    box-shadow: 0 0 128px 0 rgba(0,0,0,0.1),
                0 32px 64px -48px rgba(0,0,0,0.5);
    transform-origin: bottom-right;
    transition: all 0.6s ease;
}
.show-chatbot .chatbot{
    transform: scale(1);
    opacity: 1;
    pointer-events: auto ;
}
.chatbot-header{
    background: #724ae8;
    padding:16px 0px;
    text-align: center;
    position: relative;
}
.chatbot-header h2{
    color: #fff;
    font-size: 1.4rem;
}
.chatbot-header span{
    position: absolute;
    right: 20px;
    top: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    transform: translateY(-50%); 
}
.chatbot .chatbox{
    height: 510px;
    border-color: black;
    overflow-y: auto;
    padding: 30px 20px 100px;
}
.chatbox .chat{
    display: flex;
}
.chatbox .incoming span{
    height: 32px;
    width:32px;
    color: #fff;
    background: #724ae8;
    text-align: center;
    line-height: 32px;
    border-radius: 4px;
    margin: 0 10px 7px 0;
}
.chatbox .outgoing{
    margin: 20px 0;
    justify-content: flex-end;
}
.chatbox .chat p{
    color: #fff;
    font-size: 0.95rem;
    padding: 12px 16px;
    white-space: pre-wrap;
    border-radius: 10px 10px 0 10px;
    background: #724ae8;

}
.chatbox .incoming p{
    color: black;
    background: #f2f2f2;
    border-radius: 10px 10px 10px 0px;
}
.chatbot .chat-input{
    position: absolute;
    bottom: 0;
    border-top: 2px solid #040404;
    width: 100%;
    display: flex;
    gap: 5px;
    background: #dcd3d3;
    padding: 5px 20px;
}
.chat-input textarea{
    height: 55px;
    width: 100%;
    border: none;
    outline: none;
    background: #dcd3d3;
    font-size: 0.95rem;
    resize: none;
    max-height: 180px;
    padding: 16px 15px 16px 0;
}
.chat-input span{
    align-self: flex-end;
    color: #724ae8;
    font-size: 1.35rem;
    height: 55px;
    line-height: 55px;
    cursor: pointer;
}

@media(max-width: 490px){
    .chatbot {
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    .chatbot .chatbox{
        height: 90%;
    }
    .chatbot-header span{
        display: block;
    }
}
