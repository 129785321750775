/* Kondapaneni, Charan Sai - 1002065248 || 
Ketkar, Abhishek - 1002065640 
Kunchakuri, Harshitha - 1002111389
Rajendran, Naveen Kumar - 1002039449
Rachakonda, Sai Prasanna - 1002090739
*/
.img-homepage{
    margin-top: 10%;
    max-height: 600px;
    max-width: 600px;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.img-login{
    margin-top: 15%;
    max-height: 600px;
    height: 80%;
    width: 95%;
    max-width: 600px;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.img-forgotpassword{
    margin-top: 15%;
    max-height: 500px;
    max-width: 500px;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.img-signup{
    margin-top: 15%;
    max-height: 600px;
    max-width: 600px;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.img-profile{
    margin-top: 15%;
    max-height: 600px;
    max-width: 600px;
}