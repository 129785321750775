/* Kondapaneni, Charan Sai - 1002065248 || 
Ketkar, Abhishek - 1002065640 
Kunchakuri, Harshitha - 1002111389
Rajendran, Naveen Kumar - 1002039449
Rachakonda, Sai Prasanna - 1002090739
*/
.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #b68ee4;
}

.header .logo {
    font-size: 45px;
    font-family: 'Sriracha', cursive;
    color: #000;
    text-decoration: none;
    margin-left: 30px;
}

.nav-items {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #b68ee4;
    margin-right: 20px;
}

.nav-items a {
    text-decoration: none;
    color: #000;
    padding: 35px 20px;
    transition: all 500ms;
}

.nav-items a:hover{
    font-size: 40px;
    font-size-adjust: 20px;
}

