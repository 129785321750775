/* Kondapaneni, Charan Sai - 1002065248 || 
Ketkar, Abhishek - 1002065640 
Kunchakuri, Harshitha - 1002111389
Rajendran, Naveen Kumar - 1002039449
Rachakonda, Sai Prasanna - 1002090739
*/
.p-homepage{
    margin: 0 auto;
    max-width: 960px;
    padding: 0 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: large;
    font-weight: normal;
}

.container {
    margin-left: 30%;
    width: fit-content;
    border-color: #000;
    border-top: 100%;
    border-bottom: 100%;
}
.p-pagetitle{
    font-size: 200%;
    font-family: Helvetica, Arial, sans-serif;
    margin-left: 1%;
    text-align: left;
}
.p1{
    font-size: 100%;
    font-family: Helvetica, Arial, sans-serif;
    margin-left: 1%;
    padding: 0px 10px;
}