/* Kondapaneni, Charan Sai - 1002065248 || 
Ketkar, Abhishek - 1002065640 
Kunchakuri, Harshitha - 1002111389
Rajendran, Naveen Kumar - 1002039449
Rachakonda, Sai Prasanna - 1002090739
*/
.coursetile{
    position: absolute;
    height: 600px;
    width: 275px;
    opacity: 0.9;
    background-color: white;
    border-radius: 15px;
    border-color: #000;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.coursetile header{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #04AA6D;
    padding:16px 0px;
    text-align: center;
    position: relative;
}
.coursetile:hover{
    opacity: 1;
}
.coursetile header .name{
    font-size: 30px;
    font-family: 'Sriracha';
    color: #ffffff;
    text-decoration: none;
    text-align: center;
}
.coursetile p{
    padding: 0px 16px;
    font-size: larger;
    text-align: left;
}
.coursetile1{
    position: absolute;
    height: 375px;
    width: 275px;
    opacity: 0.9;
    background-color: white;
    border-radius: 15px;
    border-color: #000;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.coursetile1 header{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #04AA6D;
    padding:16px 0px;
    text-align: center;
    position: relative;
}
.coursetile1:hover{
    opacity: 1;
}
.coursetile1 header .name{
    font-size: 30px;
    font-family: 'Sriracha';
    color: #ffffff;
    text-decoration: none;
    text-align: center;
}
.coursetile1 p{
    padding: 0px 16px;
    font-size: larger;
    text-align: left;
}
.coursetile2{
    position: absolute;
    margin-top: 27%;
    height: 200px;
    width: 275px;
    opacity: 0.9;
    background-color: white;
    border-radius: 15px;
    border-color: #000;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.coursetile2 header{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #04AA6D;
    padding:16px 0px;
    text-align: center;
    position: relative;
}
.coursetile2:hover{
    opacity: 1;
}
.coursetile2 header .name{
    font-size: 30px;
    font-family: 'Sriracha';
    color: #ffffff;
    text-decoration: none;
    text-align: center;
}
.coursetile2 p{
    padding: 0px 16px;
    font-size: larger;
    text-align: left;
}

.coursetile-p{
    position: absolute;
    height: 300px;
    width: 275px;
    opacity: 0.9;
    background-color: white;
    border-radius: 15px;
    border-color: #000;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}
.coursetile-p header{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #04AA6D;
    padding:16px 0px;
    text-align: center;
    position: relative;
}
.coursetile-p:hover{
    opacity: 1;
}
.coursetile-p header .logo{
    font-size: 30px;
    font-family: 'Sriracha', cursive;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
}
.coursetile-p p{
    padding: 0px 16px;
    font-size: larger;
    text-align: left;
}